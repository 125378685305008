import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="notification-triggers"
export default class extends Controller {
  static targets = ['delayInput']

  addDelay() {
    this.delayInputTarget.value = true
    // this.refreshButtonTarget.click()
  }
}
