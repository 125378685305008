import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['documentsInputs']

  connect(e) {
    addEventListener('direct-upload:initialize', (event) => {
      const {
        detail: { id, file },
      } = event

      // Hide the list of filenames that the file input shows
      document.getElementById(`direct-upload-${id}`).classList.add('sr-only')

      document.getElementById(`file-form-container-${btoa(file.name)}`).insertAdjacentHTML(
        'beforeend',
        `
        <div class="progress-bar w-full bg-gray-200 rounded-full h-2.5">
          <div id="direct-upload-progress-bar-${id}" class="bg-blue-600 h-2.5 rounded-full" style="width: 0%"></div>
        </div>
      `,
      )
    })

    addEventListener('direct-upload:progress', (event) => {
      const { id, progress } = event.detail
      const progressElement = document.getElementById(`direct-upload-progress-bar-${id}`)
      progressElement.style.width = `${progress}%`
    })

    addEventListener('direct-upload:end', (event) => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-progress-bar-${id}`)
      element.classList.add('bg-green-600')
    })
  }

  onFilesChanged(e) {
    const params = new URLSearchParams()
    params.append('target', this.documentsInputsTarget.id)

    post(`nested_form?${params.toString()}`, {
      body: JSON.stringify(
        Array.from(e.target.files).map((file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
        })),
      ),
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })
  }
}
