import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['loader', 'replace']

  show() {
    this.loaderTarget.classList.remove('hidden')
    this.replaceTarget.classList.add('hidden')
  }

  hide() {
    this.loaderTarget.classList.add('hidden')
    this.replaceTarget.classList.remove('hidden')
  }
}
