import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'clearButton']

  connect() {
    const checkSearch = () => {
      if (this.inputTarget.value === '') {
        this.clearButtonTarget.classList.add('hidden')
      } else {
        this.clearButtonTarget.classList.remove('hidden')
      }
    }

    this.inputTarget.addEventListener('input', checkSearch)

    checkSearch()
  }

  clear() {
    this.inputTarget.value = ''

    // Create and dispatch the event
    var event = new Event('input', {
      bubbles: true,
      cancelable: true,
    })
    this.inputTarget.dispatchEvent(event)
  }
}
