import { Controller } from '@hotwired/stimulus'
import { Grid, html } from 'gridjs'
import { RowSelection } from 'gridjs/plugins/selection'
import dayjs from 'dayjs'
import specs from './table_specs'
import { get } from '@rails/request.js'

const getDataFromTable = (table, columns) =>
  Array.from(table.rows).map((row) =>
    Array.from(row.cells).map((cell, index) => ((columns[index] || {}).formatter ? cell.innerHTML : cell.textContent)),
  )

const getLabels = (target) => Array.from(target.children).map((child) => child.innerHTML)

export default class extends Controller {
  static targets = [
    'data',
    'wrapper',
    'spec',
    'labels',
    'isCompact',
    'pageSize',
    'dateFormatString',
    'searchEnabled',
    'search',
    'initialSearch',
  ]
  static outlets = ['bulk-edit-issuances']

  disconnect() {
    this.wrapperTarget.children[0].remove()
  }

  connect() {
    const labels = getLabels(this.labelsTarget)
    const tableSpec = specs[this.specTarget.innerHTML]
    let columns = tableSpec ? tableSpec(labels) : labels

    if (this.hasBulkEditIssuancesOutlet)
      columns.unshift({
        id: 'rowSelection',
        plugin: { component: RowSelection },
        width: '50px',
        sort: false,
      })

    // This now checks if the column is formatted and takes innerHTML or textContent accordingly
    const data = getDataFromTable(this.dataTarget, columns)

    const hasData = data && data.length > 0

    const hasRowLinks = hasData && data[0].length !== labels.length
    if (hasRowLinks) columns.push({ hidden: true, name: 'rowLink' })

    if (columns) {
      const dateFormatString = this.dateFormatStringTarget.innerHTML
        .replace('%d', 'DD')
        .replace('%m', 'MM')
        .replace('%Y', 'YYYY')
      columns = this.setDateComparator(columns, dateFormatString)
    }

    if (!hasData && tableSpec) columns.forEach((c) => (c.sort = null))

    const pageSize = Number(this.pageSizeTarget.innerText)
    const pagination =
      data.length > pageSize
        ? {
            summary: this.isCompactTarget.innerText === 'false',
            limit: pageSize,
          }
        : false

    const initialSearch = this.initialSearchTarget.innerHTML
    const search =
      this.searchEnabledTarget.innerText === 'true'
        ? {
            keyword: initialSearch,
          }
        : false

    const table_child_index = (search ? 1 : 0) + (pagination ? 1 : 0)

    const table_child_class = `[&:nth-child(${table_child_index})]:[&>*]:md:rounded-t-lg [&:nth-child(${
      1 + table_child_index
    })]:[&>*]:md:rounded-t-lg [&>.gridjs-wrapper]:border-b-0`

    const grid = new Grid({
      sort: hasData,
      fixedHeader: true,
      data,
      autoWidth: false,
      pagination: pagination,
      language: {
        noRecordsFound: 'No records found',
      },
      className: {
        thead: 'bg-gray-50',
        sort: `h-6 stroke-2 bg-contain float-none ml-1 absolute top-1/2 -translate-y-1/2 opacity-60 [&.gridjs-sort-neutral]:bg-none`,
        th: 'px-1 py-2 h-4 font-medium hover:bg-gray-50 focus:bg-gray-50 border-r-0 border-l-0 text-gray-600 text-xs items-center align-middle relative [&>div]:whitespace-normal [&>div]:break-normal [&>div]:w-fit [&>div]:max-w-[80%] first:pl-4 last:pr-4',
        td: 'py-2 px-1 text-gray-600 border-l-0 border-r-0 text-sm hover bg-transparent first:pl-4 last:pr-4',
        table:
          'min-w-full break-all [&_.gridjs-checkbox]:rounded [&_.gridjs-checkbox]:float-left [&_.gridjs-checkbox]:border-gray-200',
        tr: hasRowLinks ? 'hover:bg-gray-50 cursor-pointer' : '',
        container: `p-0 rounded-lg md:p-[2px] [&>div]:shadow-none [&>.gridjs-wrapper]:border-gray-200 [&>.gridjs-wrapper]:border z-[unset] [&>.gridjs-wrapper]:z-[unset] ${
          pagination ? table_child_class : '[&>*]:md:rounded-lg'
        }`,
        pagination: 'text-sm',
        footer: 'p-2 border-gray-200 border',
        search: 'hidden',
      },
      columns,
      search,
    }).render(this.wrapperTarget)

    if (hasRowLinks) {
      grid.on('cellClick', (e, cell, colConfig, row) => {
        // Cancel row link if cell content was an anchor tag clicked
        if (
          colConfig.id?.includes('link') ||
          colConfig.id === 'rowSelection' ||
          (e.target.tagName.toLowerCase() === 'a' && !!e.target.href)
        )
          return
        window.location = row.cells[row.cells.length - 1].data
      })
    }

    grid.config.store.subscribe((state) => {
      if (!state.data || !state.rowSelection) return

      const selectedRows = state.data.rows
        .filter((row) => state.rowSelection.rowIds.includes(row.id))
        .map(({ cells }) => cells[cells.length - 1].data.split('/').pop())

      this.bulkEditIssuancesOutlets.forEach((outlet) => outlet.onRowSelectionChange(selectedRows))
    })
  }

  setDateComparator(columns, dateFormatString) {
    // Set date sorting using perferred date format
    columns.forEach((c) => {
      if (c.sort?.compare === 'date') {
        c.sort.compare = (a, b) => {
          if (a === '') return 1
          if (b === '') return -1

          const dateA = dayjs.tz(a, dateFormatString)
          const dateB = dayjs.tz(b, dateFormatString)

          return dateA > dateB ? 1 : dateB > dateA ? -1 : 0
        }
      }
    })

    return columns
  }

  search(e) {
    // Find the gridjs searchbox and set the value
    var el = document.querySelector('.gridjs-search-input')
    el.value = e.target.value

    // Create and dispatch the event
    var event = new Event('input', {
      bubbles: true,
      cancelable: true,
    })
    el.dispatchEvent(event)
  }

  refresh(e) {
    const refreshPath = e.target.dataset.refreshPath

    const params = new URLSearchParams(refreshPath.split('?')[1])
    params.append('search', this.searchTarget.value)

    get(`${refreshPath.split('?')[0]}?${params.toString()}`, {})
  }
}
