import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['area', 'collapsedArea', 'expandButton', 'collapseButton']

  expand() {
    this.areaTarget.classList.remove('hidden')
    this.collapseButtonTarget.classList.remove('hidden')
    this.expandButtonTarget.classList.add('hidden')
    if (this.hasCollapsedAreaTarget) {
      this.collapsedAreaTarget.classList.add('hidden')
    }
  }

  collapse() {
    this.areaTarget.classList.add('hidden')
    this.collapseButtonTarget.classList.add('hidden')
    this.expandButtonTarget.classList.remove('hidden')
    if (this.hasCollapsedAreaTarget) {
      this.collapsedAreaTarget.classList.remove('hidden')
    }
  }
}
