import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'forecasts',
    'projectId',
    'creditTypeId',
    'removalMethodId',
    'forecastUntilProductionPeriod',
    'form',
  ]

  addForecast(e, forecast_until_production_period) {
    const formData = new FormData(this.formTarget)
    const params = new URLSearchParams(formData)
    params.append('target', this.forecastsTarget.id)
    params.append('index', this.forecastsTarget.children.length)
    if (forecast_until_production_period)
      params.append('forecast_until_production_period', forecast_until_production_period)

    get(`/projects/${this.projectIdTarget.innerText}/issuances/nested_bulk_form?${params.toString()}`, {
      responseKind: 'turbo-stream',
    })
  }

  addForecastsUntil(e) {
    this.addForecast(e, this.forecastUntilProductionPeriodTarget.value)
  }

  removeForecast(e) {
    const index = e.target.dataset.index
    const el = Array.from(this.forecastsTarget.children).find((child) => child.dataset.index === index)
    if (el) el.remove()
  }

  onCommonFieldChange(e) {
    this.forecastsTarget.querySelectorAll('input[type=hidden]').forEach((hiddenField) => {
      if (hiddenField.name.includes(e.target.name)) hiddenField.value = e.target.value
    })
  }

  onProductionPeriodChange(e) {
    const index = e.target.dataset.index
    const value = e.target.value.includes(',') ? e.target.value.split(',')[1] : e.target.value
    this.forecastsTarget
      .querySelector(`#project_issuances_attributes_${index}_expected_issuance_date`)
      ._flatpickr.setDate(value)
  }
}
