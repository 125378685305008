import ColorPicker from 'stimulus-color-picker'

export default class extends ColorPicker {
  onSave(color) {
    super.onSave(color)

    // The ColorPicker controller's way of setting the input value does not correctly
    //   fire a change event, making it hard to listen to changes from other controllers
    this.inputTarget.dispatchEvent(new Event('change'))
  }
}
