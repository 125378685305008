import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template']

  addItem(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML
    event.target.insertAdjacentHTML('beforebegin', content)
  }

  removeItem(event) {
    event.preventDefault()
    event.target.closest('.row').remove()
  }
}
