import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const BAR_COLORS = ['#175CD3', '#53B1FD', '#364152', '#919EEF', '#8D976D']

// Register the plugin to all charts:
Chart.register(ChartDataLabels)

export default class ChartController extends Controller {
  static targets = ['chart']

  static ELEMENT_ID = 'chart'
  static DEFAULT_OPTIONS = { responsive: true, maintainAspectRatio: false }

  connect() {
    const labels = JSON.parse(this.element.dataset.labels)
    const datasets = JSON.parse(this.element.dataset.chartDatasets)
    const axisFormatting = this.element.dataset.axisFormatting ? JSON.parse(this.element.dataset.axisFormatting) : ''
    const tooltipDataTitle = this.element.dataset.tooltipDataTitle
    const useDataLabels = this.element.dataset.useDataLabels
    const hideLegend = this.element.dataset.hideLegend
    const chartType = this.element.dataset.chartType || 'bar'
    const yAxisTitle = this.element.dataset.yAxisTitle
    const xAxisTitle = this.element.dataset.xAxisTitle
    const barPercentage = this.element.dataset.barPercentage || 1

    var canvas = this.chartTarget
    var ctx = canvas.getContext('2d')

    const type = chartType === 'waterfall' ? 'bar' : chartType

    const cleanedFormatting = {
      x: this.extractFormatting(axisFormatting.x),
      y: this.extractFormatting(axisFormatting.y),
    }

    if ((axisFormatting || {}).tooltip) {
      cleanedFormatting.tooltip = this.extractFormatting(axisFormatting.tooltip)
    } else {
      cleanedFormatting.tooltip = cleanedFormatting.y
    }

    const data = {
      datasets: datasets.map(({ label, data, stack, backgroundColor }, index) => ({
        type,
        label,
        data,
        stack,
        borderColor: '#0000000',
        backgroundColor: backgroundColor || [BAR_COLORS[index]],
        barPercentage,
      })),
      labels: labels,
    }

    const yNumberFormat = this.numberFormat(cleanedFormatting.y)

    const tooltipNumberFormat = this.numberFormat(cleanedFormatting.tooltip)

    const plugins = [this.axisTooltip()]

    const options = {
      plugins: {
        legend: {
          display: hideLegend !== 'true',
          labels: {
            fontFamily: 'Inter',
          },
        },
        title: {
          fontFamily: 'Inter',
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const value = context.parsed._custom
                ? context.parsed._custom.barEnd - context.parsed._custom.barStart
                : context.parsed.y
              return (
                cleanedFormatting.tooltip.pre +
                tooltipNumberFormat.format(value / cleanedFormatting.tooltip.scale) +
                cleanedFormatting.tooltip.post
              )
            },
          },
        },
        datalabels: {
          display: useDataLabels === 'true',
          color: '#fff',
          textStrokeColor: '#000',
          textStrokeWidth: 1,
          textShadowColor: '#000',
          textShadowBlur: 2,
          borderRadius: 10,
          padding: {
            top: 2,
            bottom: 2,
          },
          formatter: function (value, context) {
            const displayVal = value[1] - value[0] > 0 ? value[1] - value[0] : null
            return (
              displayVal &&
              cleanedFormatting.y.pre +
                yNumberFormat.format(displayVal / cleanedFormatting.y.scale) +
                cleanedFormatting.y.post
            )
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          title: {
            display: xAxisTitle !== undefined,
            text: xAxisTitle,
          },
          ticks: {
            fontFamily: 'Inter',
            // Add pre and post formatting to existing formatting
            callback: function (value, index) {
              return (
                cleanedFormatting.x.pre +
                this.getLabelForValue(value / cleanedFormatting.x.scale) +
                cleanedFormatting.x.post
              )
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          title: {
            display: yAxisTitle !== undefined,
            text: yAxisTitle,
          },
          ticks: {
            fontFamily: 'Inter',
            maxTicksLimit: 5,
            // Add pre and post formatting to existing formatting
            callback: function (value, index) {
              return (
                cleanedFormatting.y.pre +
                yNumberFormat.format(value / cleanedFormatting.y.scale) +
                cleanedFormatting.y.post
              )
            },
          },
        },
      },
    }

    if (chartType == 'waterfall') {
      options.categoryPercentage = 1.0
    }

    if (tooltipDataTitle == 'true') {
      options.plugins.tooltip.callbacks.title = function (context) {
        return context[0].dataset.label
      }
    }

    const config = {
      data,
      plugins,
      options,
    }

    new Chart(canvas, config)
  }

  gradient(start_color, end_color, ctx) {
    var gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, start_color)
    gradient.addColorStop(1, end_color)

    return gradient
  }

  extractFormatting(jsonFormatting) {
    return {
      pre: (jsonFormatting || {}).pre || '',
      post: (jsonFormatting || {}).post || '',
      decimals: (jsonFormatting || {}).decimals,
      scale: (jsonFormatting || {}).scale || 1,
    }
  }

  numberFormat(format) {
    const formatOptions =
      format.decimals != undefined
        ? {
            minimumFractionDigits: format.decimals,
            maximumFractionDigits: format.decimals,
          }
        : {}
    return new Intl.NumberFormat('en-US', formatOptions)
  }

  findLabel(labels, evt) {
    let found = false
    let res = null

    labels.forEach((l) => {
      l.labels.forEach((label) => {
        if (evt.x > label.x - 10 && evt.x < label.x2 + 10 && evt.y > label.y - 10 && evt.y < label.y2 + 10) {
          res = label
          res.axis = l.scaleId
          found = true
        }
      })
    })

    return [found, res]
  }

  getLabelHitboxes(scales) {
    return Object.values(scales).map((s) => {
      return {
        scaleId: s.id,
        labels: s._labelItems.map((e, i) => {
          return {
            x: e.options.translation[0] - s._labelSizes.widths[i] / 2,
            x2: e.options.translation[0] + s._labelSizes.widths[i] / 2,
            y: e.options.translation[1] - s._labelSizes.heights[i] / 2,
            y2: e.options.translation[1] + s._labelSizes.heights[i] / 2,
            label: e.label,
            index: i,
          }
        }),
      }
    })
  }

  showAxisTooltip(context, label, completed_at) {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip')

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'chartjs-tooltip'
      tooltipEl.innerHTML = '<table></table>'
      document.body.appendChild(tooltipEl)
    }
    tooltipEl.classList.remove('hidden')

    const tooltipModel = context.tooltip

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform')
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign)
    } else {
      tooltipEl.classList.add('no-transform')
    }

    // Set Text
    const labelDescriptions = JSON.parse(this.element.dataset.labelDescriptions || '{}')

    const description = (labelDescriptions[label.axis] || [])[label.index]

    if (description === undefined) {
      tooltipEl.classList.add('hidden')
    }

    const titleLines = [label.label]
    const bodyLines = [description]

    let innerHtml = '<thead>'

    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>'
    })
    innerHtml += '</thead><tbody>'

    bodyLines.forEach(function (body, i) {
      innerHtml += '<tr><td><span>' + body + '</span></td></tr>'
    })
    innerHtml += '</tbody>'

    let tableRoot = tooltipEl.querySelector('table')
    tableRoot.innerHTML = innerHtml

    const position = context.canvas.getBoundingClientRect()

    // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

    // Display, position, and set styles for font
    tooltipEl.style.fontSize = '12px'
    tooltipEl.style.position = 'fixed'
    tooltipEl.style.left = this.mouseX + 'px'
    tooltipEl.style.top = this.mouseY + 'px'
    tooltipEl.style.padding = '10px'
    tooltipEl.style.backgroundColor = '#000000BF'
    tooltipEl.style.color = '#fff'
    tooltipEl.style.borderRadius = '8px'
    tooltipEl.style.pointerEvents = 'none'
  }

  hideAxisTooltip(context) {
    let tooltipEl = document.getElementById('chartjs-tooltip')

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'chartjs-tooltip'
      tooltipEl.innerHTML = '<table></table>'
      document.body.appendChild(tooltipEl)
    }
    tooltipEl.classList.add('hidden')
  }

  axisTooltip() {
    return {
      id: 'customHover',
      afterEvent: (chart, event, opts) => {
        const evt = event.event

        if (evt.type === 'mouseout') {
          this.hideAxisTooltip(chart)
          return
        }

        if (evt.type !== 'mousemove') {
          return
        }

        var rect = this.chartTarget.getBoundingClientRect()

        this.mouseX = rect.left + evt.x
        this.mouseY = rect.top + evt.y

        const [found, label] = this.findLabel(this.getLabelHitboxes(chart.scales), evt)

        if (found) {
          this.showAxisTooltip(chart, label, 'I added a date to the body here')
        } else {
          this.hideAxisTooltip(chart)
        }
      },
    }
  }
}
