import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'registryFormContainer',
    'registrySelect',
    'supplierSelect',
    'commissionForm',
    'projectId',
    'creditTypesWrapper',
    'creditTypeSelect',
  ]

  connect() {
    if (this.registrySelectTarget.value && this.registrySelectTarget.dataset.action)
      this.addRegistryForm({ target: this.registrySelectTarget })

    if (this.supplierSelectTarget) this.updateCommissionFormVisibility({ target: this.supplierSelectTarget })
  }

  addRegistryForm(e) {
    const registryId = e.target.value
    if (!registryId) {
      this.removeRegistryForm()
      return
    }

    const params = new URLSearchParams()
    params.append('target', this.registryFormContainerTarget.id)
    params.append('registry_id', registryId)

    get(`/registry_projects/nested_form?${params.toString()}`, {
      responseKind: 'turbo-stream',
    }).then((response) => {
      this.registryFormContainerTarget.classList.remove('sr-only')
      if (response.statusCode === 204) this.removeRegistryForm()
    })
  }

  removeRegistryForm() {
    this.registryFormContainerTarget.classList.add('sr-only')
    const el = this.registryFormContainerTarget.children[0]
    if (el) el.remove()
  }

  updateCommissionFormVisibility(e) {
    const supplierId = e.target.value
    if (!supplierId) {
      this.commissionFormTarget.classList.add('sr-only')
      const el = this.commissionFormTarget.children[0]
      if (el) el.remove()
      return
    }

    const params = new URLSearchParams()
    params.append('target', this.commissionFormTarget.id)
    params.append('supplier_id', supplierId)
    if (this.projectIdTarget && this.projectIdTarget.innerText.length > 0)
      params.append('project_id', this.projectIdTarget.innerText)

    get(`/projects/commissions/nested_form?${params.toString()}`, {
      responseKind: 'turbo-stream',
    }).then(() => {
      this.commissionFormTarget.classList.remove('sr-only')
    })
  }

  addCreditType(e) {
    const creditTypeId = e.target.value

    if (creditTypeId) {
      const el = Array.from(this.creditTypesWrapperTarget.children).find((child) => child.dataset.id === creditTypeId)

      // If the credit type was removed and readded, just unflag original for delete
      if (el) {
        var destroyField = el.querySelector('input[data-destroy="true"]')
        destroyField.value = false

        el.classList.remove('hidden')
        this.creditTypesWrapperTarget.append(el)
      } else {
        // If it doesnt exist add it through turbo stream
        const params = new URLSearchParams()
        params.append('target', this.creditTypesWrapperTarget.id)
        params.append('credit_type_id', creditTypeId)
        params.append('index', this.creditTypesWrapperTarget.children.length)

        get(`/projects/project_outputs/nested_form?${params.toString()}`, {
          responseKind: 'turbo-stream',
        })
      }

      // Remove credit type from select
      this.creditTypeSelectTarget.remove(this.creditTypeSelectTarget.selectedIndex)

      this.creditTypeSelectTarget.selectedIndex = 0
    }
  }

  removeCreditType(e) {
    const id = e.target.dataset.id
    const el = Array.from(this.creditTypesWrapperTarget.children).find((child) => child.dataset.id === id)

    if (id && el) {
      // Add credit type to select
      var opt = document.createElement('option')
      opt.value = id
      opt.innerHTML = el.textContent
      this.creditTypeSelectTarget.appendChild(opt)

      // Flag existing credit types for deletion
      var destroyField = el.querySelector('input[data-destroy="true"]')
      destroyField.value = true

      el.classList.add('hidden')
    }
  }
}
