import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { autofill } from '@mapbox/search-js-web'
import { MapboxAutofill, SessionToken } from '@mapbox/search-js-core'

export default class extends Controller {
  static targets = [
    'addressForm',
    'address1Input',
    'address2Input',
    'stateCombobox',
    'formFieldsContainer',
    'manualEditLink',
    'postalCodeInput',
    'cityInput',
    'countrySelect',
  ]

  connect() {
    this.mapboxOptions = {
      accessToken:
        'pk.eyJ1IjoiYnJhbmRvbm1hbmdyb3ZlIiwiYSI6ImNsZmxpYnNtOTAyNmU0M3JvMzRqdzE2aTkifQ.4IzyhOLKVg7a28nUzbcHOw',
    }

    this.autofillCore = new MapboxAutofill(this.mapboxOptions)
    this.sessionToken = new SessionToken()

    // This should autofill any empty fields when editing an existing address
    if (this.address1InputTarget.value) {
      this.manualAutofill()
    }

    const autofillCollection = autofill(this.mapboxOptions)
  }

  onCountryChange(e) {
    get(`/addresses/states?country=${e.target.value}&target=${this.stateComboboxTarget.id}`, {
      responseKind: 'turbo-stream',
    })
  }

  manualAutofill() {
    const result = this.autofillCore
      .suggest(this.address1InputTarget.value, {
        sessionToken: this.sessionToken,
      })
      .then((result) => {
        if ((result.suggestions || []).length !== 0) {
          const suggestion = result.suggestions[0]
          this.autofillCore.retrieve(suggestion, { sessionToken: this.sessionToken }).then((result) => {
            if ((result.features || []).length !== 0) {
              const autofillData = result.features[0].properties

              this.address2InputTarget.value = this.address2InputTarget.value || autofillData.address_line2
              this.stateInputTarget.value = this.stateInputTarget.value || autofillData.address_level1
              this.postalCodeInputTarget.value = this.postalCodeInputTarget.value || autofillData.postcode
              this.cityInputTarget.value = this.cityInputTarget.value || autofillData.address_level2
              this.countrySelectTarget.value = this.countrySelectTarget.value || autofillData.country_code.toUpperCase()
            }
          })
        }
      })
  }
}
