import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['modal']

  refresh(e) {
    const refreshPath = e.target.dataset.refreshPath

    const params = new URLSearchParams()
    params.append('target', this.modalTarget.id)

    get(`${refreshPath}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    })
  }
}
