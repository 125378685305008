import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'refreshButton',
    'vintageInput',
    'removeVintageInput',
    'fulfillmentMethodInput',
    'quantityInput',
    'fulfillmentLimit',
  ]

  addVintage() {
    this.vintageInputTarget.value = new Date().getFullYear()
    this.refreshButtonTarget.click()
  }

  removeVintage() {
    this.vintageInputTarget.value = undefined
    this.removeVintageInputTarget.value = true
    this.refreshButtonTarget.click()
  }

  toggleFulfillmentMethodInput() {
    this.fulfillmentMethodInputTarget.value =
      this.fulfillmentMethodInputTarget.value === 'automatic' ? 'manual' : 'automatic'
    this.refreshButtonTarget.click()
  }

  splitDeliveryEvenly(e) {
    const quantity = this.quantityInputTarget.value
    const issuanceCount = e.target.dataset.issuancesCount
    const limits = this.fulfillmentLimitTargets
    limits.forEach((limit) => (limit.value = quantity / issuanceCount))
  }
}
