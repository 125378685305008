import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['refreshPath', 'fromDateInput', 'untilDateInput']

  onCustomDateChange() {
    const params = new URLSearchParams()
    params.append('from_date', this.fromDateInputTarget.value)
    params.append('until_date', this.untilDateInputTarget.value)

    const urlSections = this.refreshPathTarget.innerText.split('?')

    const url = `${this.refreshPathTarget.innerText}${urlSections.length > 0 ? '&' : '?'}${params.toString()}`

    get(url, {
      responseKind: 'turbo-stream',
    })
  }
}
