import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['messageInput', 'messageInputContainer', 'messageToggle', 'emailInput']

  toggleMessageInput() {
    Array.from(this.messageToggleTarget.children).forEach(({ classList: list }) =>
      list.contains('hidden') ? list.remove('hidden') : list.add('hidden'),
    )

    if (this.messageInputContainerTarget.classList.contains('hidden')) {
      this.messageInputContainerTarget.classList.remove('hidden')
    } else {
      this.messageInputContainerTarget.classList.add('hidden')
      this.messageInputTarget.value = ''
    }
  }

  onEmailClick(e) {
    let emails = this.emailInputTarget.value
      .split(',')
      .filter((email) => email.length > 0)
      .map((email) => email.trim())
    emails.push(e.target.dataset.email)
    this.emailInputTarget.value = emails.filter((email, index) => emails.indexOf(email) == index).join(', ')
  }
}
