import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['row', 'openButton', 'closeButton']

  open(e) {
    this.toggleChildren(this.findRow(e), true)
  }

  close(e) {
    this.toggleChildren(this.findRow(e), false)
  }

  findRow(e) {
    const nodeId = e.target.dataset.nodeId
    return this.rowTargets.find((r) => r.dataset.nodeId === nodeId)
  }

  toggleChildren(row, open) {
    if (!row) return
    const nodeId = row.dataset.nodeId
    const childrenIds = row.dataset.childrenIds.split(',').filter((id) => !!id)
    if (childrenIds.length === 0) return

    const openButton = this.openButtonTargets.find((b) => b.dataset.nodeId === nodeId)
    const closeButton = this.closeButtonTargets.find((b) => b.dataset.nodeId === nodeId)
    if (open) {
      openButton.classList.add('hidden')
      closeButton.classList.remove('hidden')
    } else {
      openButton.classList.remove('hidden')
      closeButton.classList.add('hidden')
    }

    childrenIds.forEach((childId) => {
      const child = this.rowTargets.find((r) => r.dataset.nodeId === childId)
      if (child) {
        if ((open && child.classList.contains('hidden')) || !child.classList.contains('hidden'))
          child.classList.toggle('hidden')
        this.toggleChildren(child, open)
      }
    })
  }
}
