import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  toggleChild() {
    const element = event.target

    if (element.dataset.children) {
      const children = JSON.parse(element.dataset.children)
      children.forEach((id) => {
        const childRows = document.querySelectorAll(`[id='${id}']`)

        childRows.forEach((row) => {
          if (row.classList.contains('hidden')) {
            row.classList.remove('hidden')
          } else {
            row.classList.add('hidden')
          }
        })
      })

      element.classList.add('hidden')
    }
  }
}
