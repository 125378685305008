import { Slideover } from 'tailwindcss-stimulus-components'

export default class extends Slideover {
  static targets = ['blur']

  connect() {
    super.connect()

    this.handleOutsideClick = this.handleOutsideClick.bind(this)

    if (this.data.get('active') === 'true') {
      setTimeout(() => {
        this.show()
      }, 400)
    }

    this.setupOutsideClickListener()
  }

  disconnect() {
    this.removeOutsideClickListener()
    super.disconnect()
  }

  setupOutsideClickListener() {
    if (!this.outsideClickListener) {
      this.outsideClickListener = this.handleOutsideClick.bind(this)
      document.addEventListener('click', this.outsideClickListener)
    }
  }

  removeOutsideClickListener() {
    if (this.outsideClickListener) {
      document.removeEventListener('click', this.outsideClickListener)
      this.outsideClickListener = null
    }
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target) && this.constructor.activeSlider === this) {
      this.hide()
    }
  }

  _show() {
    if (this.hasBlurTarget) this.blurTarget.classList.remove('hidden')
    if (this.constructor.activeSlider && this.constructor.activeSlider !== this) {
      this.constructor.activeSlider._hide()
    }
    this.constructor.activeSlider = this

    this.element.focus()

    super._show()

    // Add event listener to stop propagation inside the slideover
    this.element.addEventListener('click', this.stopPropagation, true)
  }

  _hide() {
    if (this.hasBlurTarget) this.blurTarget.classList.add('hidden')
    if (this.constructor.activeSlider === this) this.constructor.activeSlider = null

    super._hide()

    this.element.removeEventListener('click', this.stopPropagation, true)
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.constructor.activeSlider === this) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    if (this.constructor.activeSlider === this) {
      this.hide()
    } else {
      this._show()
    }
  }

  hide() {
    this.constructor.activeSlider = null
    this._hide()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
