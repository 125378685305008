import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['symbol', 'symbolContainer', 'input']

  change(e) {
    const symbol = e.target.options[e.target.selectedIndex].dataset.symbol

    if (symbol) {
      this.symbolContainerTarget.classList.remove('hidden')
      this.inputTarget.classList.remove('rounded-l-md')
      this.symbolTarget.innerHTML = symbol
    } else {
      this.symbolContainerTarget.classList.add('hidden')
      this.inputTarget.classList.add('rounded-l-md')
    }
  }
}
