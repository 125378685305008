import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // Auto remove the flash messages
    setTimeout(() => {
      this.element.remove()
    }, 5000)
  }
}
