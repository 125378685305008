import { html } from 'gridjs'
import dayjs from 'dayjs'

const comparators = {
  number: (a, b) => {
    const [c, d] = [a, b].map((x) => x.replace(/[^0-9.-]/g, ''))
    return parseFloat(c) - parseFloat(d)
  },
  number_link: (a, b) => {
    const [c, d] = [parseHTML(a).textContent, parseHTML(b).textContent].map((x) => x.replace(/[^0-9.-]/g, ''))
    return parseFloat(c) - parseFloat(d)
  },
  fraction: (a, b) => {
    const [c, d] = [a, b].map((x) => x.split('/')[0])
    return comparators.number(c, d)
  },
  monthYear: (a, b) => {
    if (a === '') return 1
    if (b === '') return -1

    const dateA = dayjs.tz(a, 'MMMM YYYY')
    const dateB = dayjs.tz(b, 'MMMM YYYY')

    return dateA > dateB ? 1 : dateB > dateA ? -1 : 0
  },
  html: (a, b) => {
    return htmlContent(a).localeCompare(htmlContent(b))
  },
  html_number: (a, b) => {
    return comparators.number(htmlContent(a), htmlContent(b))
  },
}

const parseHTML = (html) => {
  const t = document.createElement('template')
  t.innerHTML = html
  return t.content
}

const createSpec = (spec) => (labels) =>
  labels.map((label, i) => ({
    name: label,
    ...(spec.find(({ index }) => index === i) || {}),
  }))

const formatStatus = (value) =>
  html(`
    ${value}
`)

const htmlContent = (text) =>
  [
    ...new DOMParser().parseFromString(text.replace(/(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g, ''), 'text/xml')
      .children,
  ]
    .map((s) => s.textContent)
    .join(' ')

export default {
  orders: createSpec([
    // ID
    { index: 0, sort: { compare: comparators.number } },
    // Deal date
    { index: 1, sort: { compare: 'date' } },
    // Order Value
    { index: 3, sort: { compare: comparators.number } },
    // Delivery Status
    { index: 4, formatter: (v) => html(v) },
    // Payment Status
    { index: 5, formatter: (v) => html(v) },
    // Status
    { index: 6, formatter: (v) => html(v) },
  ]),
  customers: createSpec([
    // Total order value
    { index: 1, sort: { compare: comparators.number } },
  ]),
  'project-issuances': createSpec([
    // Units
    { index: 2, sort: { compare: comparators.number } },
    // Production start date
    { index: 3, sort: { compare: 'date' } },
    // Production end date
    { index: 4, sort: { compare: 'date' } },
    // Expected issuance date
    { index: 5, sort: { compare: 'date' } },
    // Issuance date
    { index: 6, sort: { compare: 'date' } },
    // Status
    { index: 7, formatter: formatStatus },
  ]),
  issuances: createSpec([
    { index: 0, sort: { compare: comparators.number } },
    // Project
    { index: 1, formatter: (v) => html(v) },
    // Quantity
    { index: 3, sort: { compare: comparators.number } },
    // Production period start date
    { index: 5, sort: { compare: 'date' } },
    // Production period end date
    { index: 6, sort: { compare: 'date' } },
    // Expected issuance date
    { index: 7, sort: { compare: 'date' } },
    // Issuance date
    { index: 8, sort: { compare: 'date' } },
    // Status
    { index: 9, formatter: formatStatus },
  ]),
  projects: createSpec([
    // Status
    { index: 3, formatter: (v) => html(v) },
  ]),
  inventories: createSpec([
    // Available
    { index: 1, sort: { compare: comparators.number } },
  ]),
  removal_partners: createSpec([
    // Default
    { index: 2, formatter: (v) => html(v) },
  ]),
  credit_types: createSpec([{ index: 2, formatter: (v) => html(v) }]),
  account_users: createSpec([
    // Status
    { index: 2, formatter: formatStatus },
    { index: 4, sort: false, formatter: (v) => html(v) },
  ]),
  invitations: createSpec([
    { index: 4, sort: false, formatter: (v) => html(v) },
    { index: 5, sort: false, formatter: (v) => html(v) },
  ]),
  credits: createSpec([
    { index: 0, sort: { compare: comparators.number } },
    { index: 1, formatter: (v) => html(v) },
    { index: 2, sort: { compare: comparators.number } },
  ]),
  project_statuses: createSpec([
    // Badge
    { index: 0, formatter: (v) => html(v) },
    { index: 1, formatter: (v) => html(v) },
  ]),
  order_statuses: createSpec([
    // Badge
    { index: 0, formatter: (v) => html(v) },
  ]),
  enabled_currencies: createSpec([
    // Delete Button
    { index: 1, formatter: (v) => html(v), sort: false },
  ]),
  addresses: createSpec([
    // Primary
    { index: 6, formatter: (v) => html(v), sort: false },
    // Delete/Edit Button
    { index: 7, formatter: (v) => html(v), sort: false },
  ]),
  contacts: createSpec([
    // Primary
    { index: 3, formatter: (v) => html(v), sort: false },
    // Delete/Edit Button
    { index: 4, formatter: (v) => html(v), sort: false },
  ]),
  issuance_deliveries: createSpec([
    // Edit Button
    { id: 'edit_link', index: 4, formatter: (v) => html(v) },
  ]),
  transaction_history: createSpec([
    // Delivery ID
    { index: 0, formatter: (v) => html(v), width: '200px' },
    // Project
    { index: 1, formatter: (v) => html(v) },
    // Status Badge
    { index: 7, formatter: (v) => html(v) },
    // Modal Button
    { index: 8, formatter: (v) => html(v) },
  ]),
  project_deliveries: createSpec([
    // Order ID
    {
      index: 1,
      formatter: (v) => html(v),
      sort: { compare: comparators.html_number },
    },
    // Customer
    {
      index: 2,
      formatter: (v) => html(v),
      sort: { compare: comparators.html },
      width: '200px',
    },
    // Quantity
    { index: 3, sort: { compare: comparators.number } },
    // Unit Price
    { index: 4, sort: { compare: comparators.number } },
    // Value
    { index: 5, sort: { compare: comparators.number } },
    // Delivery date
    { index: 6, sort: { compare: 'date' } },
    // Delivery Status
    { index: 7, formatter: (v) => html(v) },
    // Order Status
    { index: 8, formatter: (v) => html(v) },
    // Slideover
    { index: 9, formatter: (v) => html(v) },
  ]),
  documents: createSpec([
    // Uploaded by
    { index: 2, sort: { compare: 'date' } },
    // Uploaded on
    { index: 3, sort: { compare: 'date' } },
  ]),
  images: createSpec([
    // Preview
    { index: 1, formatter: (v) => html(v), sort: false },
    // Order
    { index: 3, sort: { compare: comparators.number } },
    // Uploaded on
    { index: 5, sort: { compare: 'date' } },
  ]),
  future_inventory: createSpec([
    // Vintage
    { index: 2, sort: { compare: comparators.number } },
    // Available
    { index: 3, sort: { compare: comparators.number } },
    // Expected issuance date
    { index: 4, sort: { compare: 'date' } },
  ]),
  fulfillment_instructions: createSpec([
    { index: 0, sort: false },
    { index: 1, sort: false },
    { index: 2, sort: false },
    { index: 3, formatter: (v) => html(v), sort: false },
  ]),
  retirement_details: createSpec([
    // Edit button
    { index: 7, formatter: (v) => html(v) },
  ]),
  supplier_statements: createSpec([
    // Date
    { index: 1, sort: { compare: comparators.monthYear } },
    // Date
    { index: 2, sort: { compare: 'date' } },
    // Download Button
    { index: 3, formatter: (v) => html(v) },
  ]),
  activity_feed: createSpec([{ index: 4, formatter: (v) => html(v), sort: false }]),
  nexus_libraries_emissions_factors: createSpec([{ index: 1, width: '44px', formatter: (v) => html(v), sort: false }]),
  nexus_libraries_global_warming_potentials: createSpec([
    { index: 1, width: '44px', formatter: (v) => html(v), sort: false },
  ]),
  supplier_deliveries: createSpec([
    // ID
    { index: 0, sort: { compare: comparators.number }, width: '200px' },
    // Order ID
    {
      index: 1,
      formatter: (v) => html(v),
      sort: { compare: comparators.number_link },
      width: '75px',
    },
    // Customer
    {
      index: 2,
      formatter: (v) => html(v),
      sort: { compare: comparators.html },
      width: '180px',
    },
    // Quantity
    { index: 3, sort: { compare: comparators.number } },
    // Unit Price
    { index: 4, sort: { compare: comparators.number } },
    // Value
    { index: 5, sort: { compare: comparators.number } },
    // Supplier payment date
    { index: 6, sort: { compare: 'date' } },
    // Delivery Status
    { index: 7, formatter: (v) => html(v) },
    // Order Status
    { index: 8, formatter: (v) => html(v) },
    // Slideover
    { index: 9, formatter: (v) => html(v) },
  ]),
  delivery_issuances: createSpec([
    // ID
    { index: 0, sort: { compare: comparators.number } },
    // Vintage
    { index: 1, sort: { compare: comparators.number } },
    // Contribution to delivery
    { index: 2, sort: { compare: comparators.number } },
  ]),
}
