import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab']

  connect() {
    const params = new URLSearchParams()
    params.append('tab', this.tabTarget.innerText)
    window.history.pushState(window.history.state, null, `?${params.toString()}`)
  }
}
