// Custom TailwindCSS modals for confirm dialogs

const Rails = require('@rails/ujs')

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm

// Elements we want to listen to for data-confirm
const elements = ['a[data-confirm]', 'button[data-confirm]', 'input[type=submit][data-confirm]']

// Common elements for different modal types
const types = {
  default: {
    iconPath:
      'M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z',
    color: 'blue',
    commitText: 'Confirm',
    title: 'Confirm',
  },
  delete: {
    iconPath:
      'M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z',
    color: 'red',
    commitText: 'Delete',
    title: 'Delete',
  },
}

const createConfirmModal = (element) => {
  var id = 'confirm-modal-' + String(Math.random()).slice(2, -1)
  var confirm = element.dataset.confirm

  var confirmType = types[element.dataset.confirmType] || types['default']

  var content = `
    <div id="${id}" class="z-50 animated fadeIn fixed top-0 left-0 w-full h-full table" style="background-color: rgba(0, 0, 0, 0.8);">
      <div class="table-cell align-middle">
        <div class="mx-auto overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${confirmType.color}-100 sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: outline/information-circle -->
                <svg class="w-6 h-6 text-${confirmType.color}-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="${confirmType.iconPath}" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">${confirmType.title}</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">${confirm}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button data-behavior="commit" type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-${confirmType.color}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${confirmType.color}-700 focus:outline-none focus:ring-2 focus:ring-${confirmType.color}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">${confirmType.commitText}</button>
            <button data-behavior="cancel" type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  `

  var modal = document.createElement('div')
  modal.innerHTML = content

  document.body.appendChild(modal)

  element.dataset.confirmModal = `#${id}`

  modal.addEventListener('keyup', (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      element.removeAttribute('data-confirm-modal')
      modal.remove()
    }
  })

  modal.querySelector("[data-behavior='cancel']").addEventListener('click', (event) => {
    event.preventDefault()
    element.removeAttribute('data-confirm-modal')
    modal.remove()
  })
  modal.querySelector("[data-behavior='commit']").addEventListener('click', (event) => {
    event.preventDefault()

    // Allow the confirm to go through
    Rails.confirm = () => {
      return true
    }

    // Click the link again
    element.click()

    // Remove the confirm attribute and modal
    element.removeAttribute('data-confirm-modal')
    Rails.confirm = old_confirm

    modal.remove()
  })

  modal.querySelector("[data-behavior='commit']").focus()
  return modal
}

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal
}

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(', '), 'confirm', handleConfirm)
