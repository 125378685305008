import { Controller } from '@hotwired/stimulus'
import { basicSetup } from 'codemirror'
import { EditorState } from '@codemirror/state'
import { EditorView, keymap } from '@codemirror/view'
import { yaml } from '@codemirror/lang-yaml'
import { json } from '@codemirror/lang-json'
import { oneDark } from '@codemirror/theme-one-dark'

export default class extends Controller {
  static targets = ['textarea']
  static values = {
    language: String,
  }

  connect() {
    this.initializeCodeMirror()
  }

  initializeCodeMirror() {
    const initialContent = this.textareaTarget.value

    const customKeymap = [
      {
        key: 'Tab',
        run: (view) => {
          const spacesPerTab = view.state.tabSize
          const spaces = ' '.repeat(spacesPerTab)

          view.dispatch(
            view.state.update(view.state.replaceSelection(spaces), {
              scrollIntoView: true,
              userEvent: 'input',
            }),
          )

          return true
        },
      },
    ]

    this.editor = new EditorView({
      state: EditorState.create({
        doc: initialContent,
        extensions: [
          basicSetup,
          this.languageValue === 'json' ? json() : yaml(),
          EditorView.updateListener.of((update) => {
            if (update.docChanged) {
              this.textareaTarget.value = update.state.doc.toString()
            }
          }),
          EditorState.tabSize.of(2),
          keymap.of(customKeymap),
          oneDark,
        ],
      }),
      parent: document.querySelector('#editor-container'),
    })
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy()
    }
  }
}
