import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="file-upload"
export default class extends Controller {
  static targets = ['image', 'file', 'error']
  static classes = ['visibility']

  select(event) {
    const file = event.currentTarget.files[0]

    if (this.hasImageTarget) {
      this.imageTarget.src = window.URL.createObjectURL(file)
      this.imageTarget.classList.remove(this.visibilityClass)
    }

    if (this.hasFileTarget) {
      this.fileTarget.innerHTML = file.name
    }

    this.errorTarget.classList.add(this.visibilityClass)
  }

  start() {
    this.errorTarget.classList.add(this.visibilityClass)
  }

  error(event) {
    event.preventDefault()
    this.errorTarget.classList.remove(this.visibilityClass)
    console.error(event.detail.error)
  }
}
