import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inputSupplierPaymentAmountInput', 'refreshButton']

  inputSupplierPayment() {
    this.inputSupplierPaymentAmountInputTarget.value = true
    this.refreshButtonTarget.click()
  }

  inputFXRate() {
    this.inputSupplierPaymentAmountInputTarget.value = false
    this.refreshButtonTarget.click()
  }
}
