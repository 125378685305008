import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['refreshPath']

  onChange(e) {
    const url = `${this.refreshPathTarget.innerText}${e.target.value}`

    get(url, {
      responseKind: 'turbo-stream',
    })
  }
}
