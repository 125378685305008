import { Controller } from '@hotwired/stimulus'
import { Grid, html } from 'gridjs'

const updateUrlParam = (prev, key, value) => {
  const url = new URL(prev)
  const params = new URLSearchParams(url.search)
  value ? params.set(key, value) : params.delete(key)
  url.search = params.toString()

  url.pathname = url.pathname.replace('.json', '')
  window.history.replaceState({}, '', url.toString())
  url.pathname = url.pathname.replace('/orders?', '/orders.json?')

  return url.toString()
}

const getUrlParam = (key) => new URLSearchParams(new URL(window.location.href).search).get(key)

export default class extends Controller {
  static targets = ['wrapper', 'columns', 'filters']

  disconnect() {
    this.wrapperTarget.children[0].remove()
  }

  connect() {
    const columns = JSON.parse(this.columnsTarget.innerText).map((column) => ({
      id: column.id,
      name: column.name,
      hidden: column.hidden,
      sort: column.sort,
      width: column.width,
      formatter: column.type === 'html' ? (v) => html(v) : undefined,
      data: (row) => row[column.id],
    }))

    Object.entries(JSON.parse(this.filtersTarget.innerText)).map(([key, value]) =>
      updateUrlParam(window.location.href, key, value),
    )

    const url = window.location.href.replace('/orders?', '/orders.json?')

    const grid = new Grid({
      server: {
        url,
        headers: { Accept: 'application/json' },
        then: (res) => res.data,
        total: (res) => res.total,
        handle: (res) => {
          if (res.status === 404) return { data: [] }
          if (!res.ok) throw new Error('Unexpected response')
          if (!res.headers.get('Content-Type').includes('application/json')) {
            return fetch(url, { Accept: 'application/json' }).then((res) => res.json())
          }
          return res.json()
        },
      },
      pagination: {
        limit: 20,
        page: Number(getUrlParam('page') || 1) - 1,
        server: {
          url: (prev, page, page_size) =>
            updateUrlParam(updateUrlParam(prev, 'page', page + 1), 'page_size', page_size),
        },
        resetPageOnUpdate: false,
      },
      sort: {
        multiColumn: false,
        server: {
          url: (prev, columnsToSort) => {
            if (!columnsToSort.length) return prev

            let sort = []
            columnsToSort.forEach((col) => sort.push(`${col.direction === 1 ? '' : '-'}${columns[col.index].id}`))

            return updateUrlParam(prev, 'sort', sort.join(','))
          },
        },
      },
      fixedHeader: true,
      language: {
        noRecordsFound: 'No records found',
        search: {
          placeholder: 'Search by Customer',
        },
      },
      className: {
        thead: 'bg-gray-50',
        sort: 'h-6 stroke-2 bg-contain float-none ml-1 absolute top-1/2 -translate-y-1/2 opacity-60 [&.gridjs-sort-neutral]:bg-none',
        th: 'cursor-not-allowed [&.gridjs-th-sort]:cursor-pointer [&.gridjs-th-sort]:hover:bg-gray-200 px-1 py-2 h-4 font-medium border-r-0 border-l-0 text-gray-600 text-xs items-center align-middle relative [&>div]:whitespace-normal [&>div]:break-normal [&>div]:w-fit [&>div]:max-w-[80%] first:pl-4 last:pr-4',
        td: 'py-2 px-1 text-gray-600 border-l-0 border-r-0 text-sm hover bg-transparent first:pl-4 last:pr-4',
        table: 'min-w-full break-all',
        tr: 'hover:bg-gray-50 cursor-pointer',
        container:
          'p-0 rounded-lg md:p-[2px] [&>div]:shadow-none [&>.gridjs-wrapper]:border-gray-200 [&>.gridjs-wrapper]:border z-[unset] [&>.gridjs-wrapper]:z-[unset]}',
        pagination: 'text-sm',
        footer: 'p-2 border-gray-200 border',
        search: 'hidden',
      },
      columns,
    }).render(this.wrapperTarget)

    grid.on('cellClick', (e, cell, colConfig, row) => {
      // Cancel row link if cell content was an anchor tag clicked
      if (colConfig.id?.includes('link') || (e.target.tagName.toLowerCase() === 'a' && !!e.target.href)) return
      window.location = row.cells[row.cells.length - 1].data
    })

    setTimeout(() => {
      // Set initial sort UI
      const sort = getUrlParam('sort')
      sort?.split(',')?.forEach((field) => {
        const direction = field.startsWith('-') ? -1 : 1
        const id = field.replace('-', '')
        const element = this.wrapperTarget.querySelector(`th[data-column-id="${id}"] > button`)
        if (!element) return
        element.click()
        if (direction === -1) element.click()
      })
    }, 100)
  }
}
