import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['deliveries', 'currencyInput', 'beneficiaryInput', 'refreshButton']

  addDelivery() {
    const params = new URLSearchParams()
    params.append('target', this.deliveriesTarget.friendly_id)
    params.append('index', this.deliveriesTarget.children.length)
    params.append('currency', this.currencyInputTarget.value)

    get(`/orders/nested_delivery_form?${params.toString()}`, {
      responseKind: 'turbo-stream',
    })
  }

  removeDelivery(e) {
    const index = e.target.dataset.index
    const el = Array.from(this.deliveriesTarget.children).find((child) => child.dataset.index === index)
    if (el) el.remove()
  }

  addBeneficiaryDetails() {
    this.beneficiaryInputTarget.value = true
    this.refreshButtonTarget.click()
  }
}
