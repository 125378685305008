import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['messageInput']

  onVariableClick(e) {
    const cursorIndex = this.messageInputTarget.selectionStart
    this.messageInputTarget.value =
      this.messageInputTarget.value.slice(0, cursorIndex) +
      e.target.dataset.variable +
      this.messageInputTarget.value.slice(cursorIndex)
    this.messageInputTarget.focus()
  }
}
