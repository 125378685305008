import { Controller } from '@hotwired/stimulus'
import consumer from '../channels/consumer'
import { get } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: 'NotificationChannel' },
      {
        received: this._received.bind(this),
      },
    )
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  _received(data) {
    if (!data.message) return

    const params = new URLSearchParams()
    params.append('message', data.message)
    setTimeout(() => {
      get(`/add_flash?${params.toString()}`, { responseKind: 'turbo-stream' })
    }, 2000)
  }
}
