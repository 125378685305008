import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    dismissAfter: Number,
  }
  static targets = ['popover']

  // Show the popover
  mouseOver(e) {
    this.popoverTarget.classList.remove('hidden')

    // Position the bottom of the popover 10 pixels above the mouse cursor
    this.popoverTarget.style.bottom = `${window.innerHeight - e.y + 10}px`
  }

  // Hide the popover
  mouseOut() {
    this.popoverTarget.classList.add('hidden')
  }
}
