import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['registrySelect']

  onRegistryChange(e) {
    get(`/registry_projects/registry_methods?registry=${e.target.value}&target=${this.registrySelectTarget.id}`, {
      responseKind: 'turbo-stream',
    })
  }
}
