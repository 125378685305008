import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inputs', 'submit', 'count']

  onRowSelectionChange(issuanceIds) {
    this.inputsTarget.innerHTML = '' // Clear the inputs
    issuanceIds.forEach((id) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', 'issuance_ids[]')
      input.setAttribute('value', id)
      this.inputsTarget.appendChild(input)
    })

    if (issuanceIds.length > 0) {
      this.submitTarget.classList.remove('hidden')
    } else {
      this.submitTarget.classList.add('hidden')
    }

    this.countTarget.innerText = issuanceIds.length
  }
}
