import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['scrollable', 'shadow']

  connect() {
    this.checkShadow(this.scrollableTarget)
  }

  onScroll(e) {
    this.checkShadow(e.target)
  }

  checkShadow(target) {
    if (Math.ceil(target.scrollLeft + target.clientWidth) >= target.scrollWidth) {
      this.shadowTarget.classList.add('hidden')
    } else {
      this.shadowTarget.classList.remove('hidden')
    }
  }
}
